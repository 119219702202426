

.spa-input.spa-input--sparta2 {

  .spa-fieldset-label,
  .spa-input-option-label {
    -webkit-tap-highlight-color: transparent;
  }

  .spa-fieldset-label {
    font-family: $roboto-medium-family, $roboto-font-family;
    margin-bottom: $spacing-4;

        &.inline-legend-icon {
      margin-top: 7px;

      ~ .toggle-trigger {
        &:hover,
        &:focus {
          border: 1px solid $color-royal-one;
          outline: 0px;
          border-radius: 2px;
        }
      }
    }
  }

  .spa-input-options {
    &-label {
      font-size: $input-option-label-font-size;
    }

    .toggle-trigger {
      display: inline-block;
    }

    &-wrapper {
      font-size: $type-size-16;
      line-height: $line-height-16;
      margin: 0 0 $spacing-8 0;
      position: relative;
      overflow-wrap: break-word;

      &:last-child {
        margin-bottom: 0;
      }

      .spa-input-option {
        cursor: pointer;
        height: 44px; 
        opacity: 0;
        padding-right: 6px;
        position: relative;
        width: 44px; 
        z-index: 3;

        ~ .spa-input-check {
          background-color: $color-brand-tertiary;
          border: 1px solid $color-gray;
          border-radius: 2px; 
          height: 20px; 
          left: 0;
          position: absolute;
          top: 0;
          transform: translate(11px, 11px); 
          transition: $input-option-box-transition;
          width: 20px; 
          z-index: 1;
        }

        &:hover,
        &:focus {
          &:disabled {
            cursor: auto;
            ~ label {
              font-weight: normal;
              cursor: auto;
            }

            ~ .spa-input-option-label:before {
              border: none;
            }
          }
        }

        &:disabled {
          ~ .spa-input-check {
            border-color: $color-gray-s20; 
          }
        }

        &:hover,
        &:focus,
        &:checked:focus
        &:checked:hover {
          ~ .spa-input-check {
            border-color: $color-royal-one;
          }
        }

        &:hover,
        &:focus,
        &:checked {
          &:disabled {
            ~ .spa-input-check {
              border-color: $color-gray-s20;
              box-shadow: none;
            }
          }
        }

        &.spa-error-form-input--validation,
        &.spa-error-form-input--required {
          ~ .spa-input-check {
            color: $color-regal-one;
            border-color: $color-regal-one;
          }
        }

        &:hover,
        &:focus {
          ~ .spa-input-option-label {
            &::before {
              border: 1px solid $color-royal-one;
              border-radius: $spacing-2;
              bottom: 0;
              content: '';
              height: calc(100% - 0px);
              left: -40px;
              position: absolute;
              right: 46px;
              top: 0px;
              width: calc(100% + 40px);
            }
          }
        }

        &--radio {
          ~ .spa-input-check {
            border-radius: 16px;

            &::after {
              background: transparent none repeat scroll 0 0;
              border: 0 solid transparent;
              border-radius: 50%;
              content: '';
              height: 0;
              left: 50%; 
              position: absolute;
              top: 50%; 
              transform: translate(-50%, -50%); 
              transition: all 0.25s ease-in-out;
              width: 0;
              z-index: 2;
            }
          }

          &:hover,
          &:focus,
          &:active {
            ~ .spa-input-option-label {
              &::before {
                left: -40px;
                width: calc(100% + 40px);
              }
            }

            ~ .spa-input-check {
              &::before {
                border-radius: 50%;
                content: '';
                height: 18px;
                left: 0;
                opacity: 1;
                position: absolute;
                top: 0;
                width: 18px;
                z-index: 1;
              }
            }

            &:disabled {
              ~ .spa-input-check {
                &::before {
                  border-color: transparent;
                }
              }
            }
          }

          &:checked {
            &.checked-error {
              ~ .spa-input-check {
                border-color: $color-regal-one;

                &::after {
                  background-color: $color-regal-one;
                }
              }
            }

            ~ .spa-input-check {
              border: 1px solid $color-royal-one;

              &::after {
                background-color: $color-royal-one;
                height: 10px; 
                width: 10px; 
              }
            }

            &:disabled {
              border: 1px solid $color-gray-s10;

              ~ .spa-input-check {
                &::after {
                  background-color: $color-gray-s20;
                }
              }
            }
          }
        }

        &--checkbox {
          &:checked {
            &.checked-error {
              ~ .spa-input-check {
                border-color: $color-regal-one;
                background-color: $color-regal-one;
              }
            }

            ~ .spa-input-check {
              background-color: $color-royal-one;
              background-image: icon(check, (color: $color-brand-tertiary));
              background-position: center;
            }

            &:disabled {
              ~ .spa-input-check {
                background-color: $color-gray-s20;

                &::before {
                  border-color: $color-brand-tertiary;
                }
              }
            }
          }

          &:disabled {
            ~ .spa-input-check {
              border-color: $color-gray-s20 !important;
            }
          }
        }

        &-label {
          color: $color-black;
          display: inline-block;
          font-size: $input-option-label-font-size;
          line-height: $line-height-24;
          margin-left: -$spacing-4; 
          max-width: calc(100% - 45px); 
          padding: $spacing-10 13px $spacing-10 0; 
          position: relative;
          top: 0px;
          vertical-align: top;
        }
      } 

      &.has-help {
        .spa-input-option-label {
          max-width: calc(100% - 84px);
        }
      }

      .toggle-trigger {
        height: 44px;
        left: 0;
        width: 44px;

        @media #{$medium-up} {
          left: -10px;
        }

        &:hover,
        &:focus {
          border: $spacing-1 solid $color-royal-one;
          border-radius: $spacing-2;
          outline: none;
        }
      }
    }

    &-list {
      &--vertical,
      &--horizontal {
        margin: 0;
        padding: $input-option-list-padding;
      }

      &--horizontal {
        .spa-input-options-wrapper {
          float: left;
          margin: 0 26px 0 0;
        }

        .horizontal-clear {
          clear: both;
        }

        .spa-input-option-label {
          max-width: calc(100% - 0px); 
        }
      }
    }

    &-hint {
      color: $bank-brown-secondary;
      font-size: $type-size-12; 
      margin: $option-group-hint-margin;
      min-height: 10px;
    }
  }

  &.spa-input-options {
    .spa-input-error-message {
      margin-left: $spacing-12;
    }
  }

  .spa-fieldset {
    &.spa-error-form-input--required {
      .spa-input-options-wrapper {

        .spa-input-check {
          border-color: $color-regal-one;
        }
      }
    } 

        .spa-input-box {
      .toggle-trigger-link {
          margin-left: 0;
        }

            .spa-input-error-message {
        margin-left: 0;
      }
    }
  }
}



.spa-input-option,
.spa-input-options {
  &:checked {

    ~ .spa-input-option-conditional-content {
      > .spa-input-conditional-content-wrapper {
        display: inherit;
        padding: $spacing-10;
      }
    }
  }

  &.spa-input-options--inverse {
    color: $color-brand-tertiary;

    .spa-input-options-hint {
      color: #F1EFEC;
    }

    .toggle-trigger-link {
      color: $color-brand-tertiary;  
      text-decoration: underline;

      &:hover,
      &:focus {
        color: #F1EFEC;
        text-decoration: none;
      }
    }
  }

  .flex-wrapper {
    display: flex;
    justify-content: flex-start;

    .toggle-trigger.svg-button {
      min-width: 20px;
    }
  }
}



@media print {
  * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}
