@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;





@import '_config.scss';



[data-sparta-container] {

  .spa-input {

    &.spa-input--sparta2, &.spa-input--condensed {
      @include default-skin();








      @import '_helpers/_shared.scss';




      @import '_helpers/input-toggle-trigger.scss';




      @import '_helpers/input-help-dropdown.scss';



      &.spa-input-box {



        @import 'input-types/input-datepicker.scss';
        @import 'input-types/input-currency.scss';
        @import 'input-types/input-textarea-box.scss';
        @import 'input-types/input-textarea.scss';
        @import 'input-types/input-char-count.scss';
        @import 'input-types/input-select.scss';
      }



      @import 'input-types/input-fieldset.scss';

          }


    &.spa-input--condensed {


      &.spa-input-box {

        .spa-input-text, .spa-input-textarea {
          font-size: 14px; 
          height: 36px;
        }

        .toggle-trigger, .datepicker-icon--calendar {
          height: 28px;
        }

        .spa-input-select ~ .toggle-trigger-box::before, .toggle-trigger-help::after {
          height: 21px;
        }

        .spa-input-select {
          padding: $spacing-8 36px $spacing-8 $spacing-8;
          height: 36px;
        }

        &.has-help.spa-input-box .spa-input-select {
          background-position: calc(100% - 50px) center;
        }
      } 
    }
  }





  .spa-input-conditional-content-container {
    display: none !important;
  }
  .spa-input-conditional-content-wrapper {
    display: none;
  }



  @import 'input-types/input-checkbox-radio.scss';
}