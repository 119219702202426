.spa-char-count {
  background-color: $color-brand-tertiary;
  font-family: $roboto-family;
  font-size: 12px;
  margin: 0;
  text-align: right;
  top: 155px;
  z-index: 11;
}

.spa-char-count--normal {
  color: $color-gray;
  font-weight: normal;
}

.spa-char-count--overlimit {
  color: $color-regal-one;
  font-weight: bold;
}

.spa-char-count--loaded {
  display: inline;
}

.spa-char-count--scrollbar {
  right: 17px;
}

.spa-char-warning {
  background-color: $color-brand-tertiary;
  color: $color-regal-one;
  font-family: $roboto-family;
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  text-align: right;
  top: 155px;
  z-index: 11;
}

.spa-char-warning--show {
  display: inline;
}

.spa-char-warning--hide {
  display: none;
}
