







$input-z-index-step: 10;

$input-meta-font-size: 12px;
$input-standard-font-size: 20px;

$input-standard-left-position: 10px; 
$input-standard-top-position: 3px;

$input-border-color: $bank-light-gray;

$input-standard-height: 62px;

$input-standard-background-color: $bank-white;

$input-standard-border-radius: 4px; 

$input-standard-border-width: 1px;

$input-standard-border-style: solid;

$input-standard-border-color: $color-gray; 

$input-standard-border: $input-standard-border-width $input-standard-border-style $input-standard-border-color;

$input-standard-padding-top: $spacing-12;
$input-standard-padding-right: $spacing-10;
$input-standard-padding-bottom: $spacing-10;
$input-standard-padding-left: $spacing-10;

$input-standard-padding: $input-standard-padding-top $input-standard-padding-right $input-standard-padding-bottom $input-standard-padding-left;

$input-standard-margin-top: 0;
$input-standard-margin-right: 0;
$input-standard-margin-bottom: $spacing-20;
$input-standard-margin-left: 0;

$input-standard-margin: $input-standard-margin-top $input-standard-margin-right $input-standard-margin-bottom $input-standard-margin-left;

$input-label-standard-font-size: $input-standard-font-size;

$input-label-empty-font-weight: normal;
$input-label-default-font-weight: bold;
$input-label-focus-font-weight: bold;

$input-hint-font-size: $input-meta-font-size;

$input-option-list-padding-top: 0;
$input-option-list-padding-right: 0;
$input-option-list-padding-bottom: 0;
$input-option-list-padding-left: 0;

$input-option-list-padding: $input-option-list-padding-top $input-option-list-padding-right $input-option-list-padding-bottom $input-option-list-padding-left;

$input-option-list-margin-top: 0;
$input-option-list-margin-right: 0;
$input-option-list-margin-bottom: 0;
$input-option-list-margin-left: 0;

$input-option-list-margin: $input-option-list-margin-top $input-option-list-margin-right $input-option-list-margin-bottom $input-option-list-margin-left;



$input-focus-border-color: $bank-light-green;
$input-label-focus-color: $bank-light-green;

$input-focus-box-shadow: 0 0 5px $bank-mid-gray;

$input-focus-background-color: $bank-white;




$input-invalid-border-color: $color-regal-one;
$input-invalid-label-color: $color-regal-one;


$input-label-default-scale: 1.0;
$input-label-focus-scale: 0.6;
$input-label-transform-origin: 0 0;

$input-label-color: $bank-mid-gray;

$input-label-left: $input-standard-left-position;
$input-label-top: $input-standard-top-position;

$input-label-left: $input-standard-left-position;

$input-label-empty-top: calc(#{$input-standard-top-position} * 4);

$input-label-transition: $spa-standard-transition;

$input-label-padding-top: 0;
$input-label-padding-right: 0;
$input-label-padding-bottom: 0;
$input-label-padding-left: 0;

$input-label-padding: $input-label-padding-top $input-label-padding-right $input-label-padding-bottom $input-label-padding-left;

$input-label-empty-padding-top: 14px;
$input-label-empty-padding-right: 0;
$input-label-empty-padding-bottom: 0;
$input-label-empty-padding-left: 0;

$input-label-empty-padding: $input-label-empty-padding-top $input-label-empty-padding-right $input-label-empty-padding-bottom $input-label-empty-padding-left;

$input-label-focus-padding-top: 0;
$input-label-focus-padding-right: 0;
$input-label-focus-padding-bottom: 0;
$input-label-focus-padding-left: 0;

$input-label-focus-padding: $input-label-focus-padding-top $input-label-focus-padding-right $input-label-focus-padding-bottom $input-label-focus-padding-left;



$input-hint-color: $bank-mid-gray;

$input-hint-top: calc(100% - 1rem - #{$input-standard-top-position}); 
$input-hint-left: $input-standard-left-position;

$input-hint-margin-top: 0;
$input-hint-margin-right: 0;
$input-hint-margin-bottom: 0;
$input-hint-margin-left: 0;

$input-hint-margin: $input-hint-margin-top $input-hint-margin-right $input-hint-margin-bottom $input-hint-margin-left;

$option-group-hint-top: $spacing-5;
$option-group-hint-right: 0;
$option-group-hint-bottom: 0;
$option-group-hint-left: $spacing-12;

$option-group-hint-margin: $option-group-hint-top $option-group-hint-right $option-group-hint-bottom $option-group-hint-left;



$input-error-right: 0;
$input-error-bottom-start: 50%;
$input-error-bottom-end: 100%;

$input-error-opacity-start: 0;
$input-error-opacity-end: 1.0;

$input-error-z-index: 100;




$input-error-text-color: $color-regal-one;

$input-error-background-color: $bank-error-pink;

$input-error-padding-top: $spacing-20;
$input-error-padding-right: $spacing-20;
$input-error-padding-bottom: $spacing-20;
$input-error-padding-left: $spacing-20;

$input-error-padding: $input-error-padding-top $input-error-padding-right $input-error-padding-bottom $input-error-padding-left;

$input-error-after-border-top-width: 10px;
$input-error-after-border-right-width: 10px;
$input-error-after-border-left-width: 10px;

$input-error-after-border-top-color: $input-error-background-color;

$input-error-after-border-top: $input-error-after-border-top-width solid $input-error-after-border-top-color;
$input-error-after-border-right: $input-error-after-border-right-width solid transparent;
$input-error-after-border-left: $input-error-after-border-left-width solid transparent;

$input-error-after-bottom: -#{$input-error-after-border-top-width};
$input-error-after-height: $input-error-after-border-top-width;
$input-error-after-width: $input-error-after-border-top-width;

$input-error-after-left: 50%;

$input-error-after-transform: translateX(-#{$input-error-after-left});



$input-text-transition: $spa-standard-transition;
$input-text-empty-opacity: 0.0;
$input-text-default-opacity: 1.0;


$input-select-padding-top: $input-standard-padding-top;
$input-select-padding-right: $input-standard-padding-right;
$input-select-padding-bottom: $input-standard-padding-bottom;
$input-select-padding-left: $input-standard-padding-left;

$input-select-padding: $input-select-padding-top $input-select-padding-right $input-select-padding-bottom $input-select-padding-left;

$input-select-margin-top: 0;
$input-select-margin-right: 0;
$input-select-margin-bottom: 1px;
$input-select-margin-left: 0;

$input-select-margin: $input-select-margin-top $input-select-margin-right $input-select-margin-bottom $input-select-margin-left;



$input-textarea-scale-factor: 3;

$input-textarea-transition: $spa-standard-transition;

$input-textarea-padding-top: $spacing-10;
$input-textarea-padding-right: $spacing-10;
$input-textarea-padding-bottom: $spacing-10;
$input-textarea-padding-left: $spacing-10;

$input-textarea-padding: $input-textarea-padding-top $input-textarea-padding-right $input-textarea-padding-bottom $input-textarea-padding-left;

$input-textarea-margin-top: 0;
$input-textarea-margin-right: 0;
$input-textarea-margin-bottom: 0;
$input-textarea-margin-left: 0;

$input-textarea-margin: $input-textarea-margin-top $input-textarea-margin-right $input-textarea-margin-bottom $input-textarea-margin-left;



$input-option-width: 20px;
$input-option-height: 20px;

$input-option-element-z-index: 3;
$input-option-box-z-index: 1;
$input-option-check-z-index: 2;

$input-option-focus-default-outline: 1px dotted;
$input-option-focus-webkit-outline: 5px auto -webkit-focus-ring-color;

$input-option-top: 4px;

$input-option-li-margin-top: 0;
$input-option-li-margin-right: 0;
$input-option-li-margin-bottom: $spacing-5;
$input-option-li-margin-left: 0;

$input-option-li-margin: $input-option-li-margin-top $input-option-li-margin-right $input-option-li-margin-bottom $input-option-li-margin-left;

$input-option-label-font-size: 16px;

$input-option-label-margin-top: 0;
$input-option-label-margin-right: 0;
$input-option-label-margin-bottom: 0;
$input-option-label-margin-left: $spacing-5;

$input-option-label-margin: $input-option-label-margin-top $input-option-label-margin-right $input-option-label-margin-bottom $input-option-label-margin-left;

$input-options-label-margin-top: 0;
$input-options-label-margin-right: 0;
$input-options-label-margin-bottom: $spacing-12;
$input-options-label-margin-left: 0;

$input-options-label-margin: $input-options-label-margin-top $input-options-label-margin-right $input-options-label-margin-bottom $input-options-label-margin-left;

$input-option-element-opacity: 0.0;

$input-option-element-width: $input-option-width;
$input-option-element-height: $input-option-height;

$input-option-cursor: pointer;

$input-option-box-top: $input-option-top;
$input-option-box-left: 0;

$input-option-box-border-width: 1px;
$input-option-box-border-style: solid;
$input-option-box-border-color: $bank-light-gray;

$input-option-box-border: $input-option-box-border-width $input-option-box-border-style $input-option-box-border-color;

$input-option-box-width: $input-option-width;
$input-option-box-height: $input-option-height;

$input-option-box-background-color: $bank-white;
$input-option-box-checked-background-color: $bank-primary-blue;

$input-option-box-transition: $spa-standard-transition;

$input-option-check-width: 12px;
$input-option-check-height: 7px;

$input-option-check-transition-timing: 0.2s;

$input-option-check-transition-property: $spa-standard-transition-property;
$input-option-check-transition-time: $input-option-check-transition-timing;
$input-option-check-transition-easing: $spa-standard-transition-easing;


$input-option-check-transition-delay: $input-option-check-transition-timing;

$input-option-check-transition: $input-option-check-transition-property $input-option-check-transition-time $input-option-check-transition-easing $input-option-check-transition-delay;

$input-option-check-border: none;

$input-option-check-transform: rotate(-45deg);

$input-option-check-background: transparent;

$input-option-check-top: $input-option-top;
$input-option-check-left: 3px;

$input-option-check-border-width: 2px;
$input-option-check-border-style: solid;
$input-option-check-border-color: $bank-white;

$input-option-check-border-left-width: $input-option-check-border-width;
$input-option-check-border-left-style: $input-option-check-border-style;
$input-option-check-border-left-color: $input-option-check-border-color;

$input-option-check-border-left: $input-option-check-border-left-width $input-option-check-border-left-style $input-option-check-border-left-color;

$input-option-check-border-bottom-width: $input-option-check-border-width;
$input-option-check-border-bottom-style: $input-option-check-border-style;
$input-option-check-border-bottom-color: $input-option-check-border-color;

$input-option-check-border-bottom: $input-option-check-border-bottom-width $input-option-check-border-bottom-style $input-option-check-border-bottom-color;

$input-option-radio-border-radius-top: 10px;
$input-option-radio-border-radius-right: 10px;
$input-option-radio-border-radius-bottom: 10px;
$input-option-radio-border-radius-left: 10px;

$input-option-radio-border-radius: $input-option-radio-border-radius-top $input-option-radio-border-radius-left $input-option-radio-border-radius-bottom $input-option-radio-border-radius-right;


@mixin spa-icon-help-trigger-focus-animation--default {
  box-shadow: 0 0 0 0 rgba($color-royal-two-s20, 0);
  transition: box-shadow .5s ease-in-out;
  transition-delay: 0s;
}

@mixin spa-icon-help-trigger--primary-default {
  border-radius: 50%;
  background-image: icon(help, (color: $color-brand-secondary));
}

@mixin spa-icon-help-trigger-inverse--primary-default {
  background-image: icon(help, (color: $color-royal-three-s50));
}

@mixin spa-input-select-arrow--default {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSI+CiAgICA8ZyBpZD0iQXNzZXQtQXJ0Ym9hcmQtUGFnZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImNoZXZyb24tZG93bi13ZWIvaWNvbi9zZWxlY3Rvci9jaGV2cm9uL2Rvd24vZ3JheSIgZmlsbD0iIzY0NjQ2NCI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJpY29uLWNoZXZyb24tZG93biIgcG9pbnRzPSIxLjQxIDIgNiA2Ljk0NDY2OTM3IDEwLjU5IDIgMTIgMy41MjIyNjcyMSA2IDEwIDAgMy41MjIyNjcyMSIvPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
}

@mixin spa-input-select-arrow--disabled {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSI+CiAgICA8ZyBpZD0iQXNzZXQtQXJ0Ym9hcmQtUGFnZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImNoZXZyb24tZG93bi13ZWIvaWNvbi9zZWxlY3Rvci9jaGV2cm9uL2Rvd24vZ3JheS1zMjAiIGZpbGw9IiNENUQ1RDUiPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iaWNvbi1jaGV2cm9uLWRvd24iIHBvaW50cz0iMS40MSAyIDYgNi45NDQ2NjkzNyAxMC41OSAyIDEyIDMuNTIyMjY3MjEgNiAxMCAwIDMuNTIyMjY3MjEiLz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
}

@mixin spa-input-calendar--default {
  background-image: icon(calendar, (color: $color-gray));
}

@mixin spa-input-calendar--focus {
  background-image: icon(calendar, (color: $color-royal-one));
}

@mixin spa-input-calendar--error {
  background-image: icon(calendar, (color: $color-regal-one));
}

@mixin spa-input-calendar--disabled {
  background-image: icon(calendar, (color: $color-gray-s20));
}

@mixin default-skin {
  &.spa-input-box {
    margin: 0 0 $spacing-24 0;

    .spa-input-text,
    .spa-input-select,
    .spa-input-textarea {
      border-radius: $input-standard-border-radius;
      border: 1px solid $input-standard-border-color;
      box-shadow: none;
      color: $color-black;
      display: inline-block;
      font-size: 16px; 
      height: 48px;

      &::-ms-clear {
        display: none;
      }

      &:focus {
        ~ .spa-input-hint {
          border-color: #000a23;
        }
      }

      &:disabled {
        ~ .toggle-trigger-box {
          &::after {
            background-color: transparent !important;
          }
        }

        ~ .spa-input-hint {
          color: $color-gray-s70;
        }
      }
    }


    .spa-input-label,
    .spa-input-text,
    .spa-input-select,
    .spa-input-textarea,
    .toggle-trigger-link {
      -webkit-tap-highlight-color: transparent;
    }
  }
}