.spa-fieldset {
  border: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: auto;


  &-label,
  .input-help,
  .toggle-trigger-link {
    margin-left: $spacing-12;
  }

  .toggle-trigger-link {
    margin-top: $spacing-8;
    margin-bottom: $spacing-8;
  }

  &-label {
    background-color: inherit;
    display: inline-block;
    font-family: $cnx-medium-family, $default-font-family;
    font-size: $type-size-16;
    font-weight: normal;
    line-height: 1.5;
    padding: 0;

    &.inline-legend-icon {
      float: left;

      + .toggle-trigger.svg-button {
        margin-top: $spacing-1;
      }
    }

    ~ .toggle-trigger {
      margin-top: 3px;
    }
  }

  .toggle-trigger {
    padding: 3px 0 9px 0;
  }

  .spa-input-options-list {
    &--vertical,
    &--horizontal {
      margin-top: $spacing-15;
    }
  }

  .spa-input-options-wrapper {
    .toggle-trigger {
      line-height: 38px;
      padding: $spacing-1 0 9px 0;
    }
  }

  &.spa-error-form-input--validation, 
  &.spa-error-form-input--required {
    .spa-input-option--radio, .spa-input-option--checkbox {
      + .spa-input-check {
        border-color: $color-regal-one;
      }
    }
  }
}
