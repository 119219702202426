&.spa-input-box--textarea {

  display: flex;
  flex-wrap: wrap;

  .spa-additional-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media(max-width: $small-max-width) {
      flex-direction: column-reverse;
    }
  }

  .toggle-trigger-box {
    position: relative;
    margin: -8px 0 0 4px;
  }

  .spa-error-container {
    width: 100%;
    display: flex;
    justify-content: space-between
  }

  .spa-char {
    min-width: 300px;
    text-align: right;

    @media #{$small-only} {
      text-align: left;
    }
  }

  .spa-char-count {
    margin-left: auto;
    top: auto;
  }

  .spa-char-warning {
    margin-left: auto;
    top: auto;
  }

  .input-help {
    width: 100%;
  }

  &.has-help {
    .spa-input-label {
      display: inline-block;
      margin-bottom: $spacing-10;
      max-width: calc(100% - 44px);
    }

    .toggle-trigger {
      &:hover, &:focus {
        border: 1px solid $color-royal-one;
        border-radius: 2px;   
      }
    }
  }

  .spa-error-form-input--required {
    &:focus {
      border-color: $color-regal-one;
    }
    &:hover {
      border-color: $color-royal-one;
    }
  }

  &.has-limit {
    .spa-input-hint {
      @media(min-width: $small-max-width) {
        width: calc(100% - 300px);
      }
    }
  }
}
