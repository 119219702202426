
& {
  .datepicker-icon-box,
  .toggle-trigger-box {
    background-color: transparent;
    cursor: pointer;
    display: inline-block;
    margin: $spacing-4 0 0 -44px;
    position: absolute;
    vertical-align: top;
    z-index: 31;
  }

  .toggle-trigger-help {
    margin-left: -92px;

    &::after {
      border-right: 1px solid $color-gray-s20;
      content: ' ';
      display: inline-block;
      height: 32px;
      margin-top: $spacing-4;
      position: absolute;
      width: 4.5px;
    }
  }

  .svg-button-help {
    &:focus,
    &:hover {
      outline: $spacing-1 solid $color-royal-one;
    }
  }

  .datepicker-icon--calendar,
  .toggle-trigger {
    background: none;
    display: inline-block;
    height: 40px;
    margin: 0;
    padding: 0;
    position: relative;
    vertical-align: top;
    width: 40px;
    z-index: 31;

    &::after {
      background-image: icon(help, (color: $color-royal-one));
      background-size: 100%;
      bottom: 0;
      content: ' ';
      font-style: normal;
      height: 24px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 24px;
    }

    &:hover,
    &:focus {
      &::after {
        background-image: icon(helpFocus, (color: $color-royal-one));
      }
    }
  }

  .form-help {
    &:hover,
    &:focus {
      border: $spacing-1 solid $color-royal-one;
      border-radius: 2px;
    }
  }

  .toggle-trigger,
  .close-trigger {
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .toggle-trigger-link {
    display: inline;
    font-size: 12px;
    line-height: 16px;
    margin: $spacing-4 0 0 0;
  }


  &.spa-input-options--inverse {
    .datepicker-icon--calendar,
    .toggle-trigger {
      &::after {
        @include spa-icon-help-trigger-inverse--primary-default;
      }
    }
  }
}
