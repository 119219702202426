.spa-input-textarea {
  border: 1px solid $input-standard-border-color;
  box-shadow: none;
  color: $color-gray;
  font-family: $roboto-family;
  font-size: 16px;
  height: #{$input-standard-height * 2};
  letter-spacing: 0;
  line-height: 24px;
  margin: $input-textarea-margin;
  padding: $input-textarea-padding;
  position: relative;
  resize: none; 
  transition: $input-textarea-transition;
  z-index: $input-z-index-step;

  &:focus {
    background-color: $input-focus-background-color;
    border-color: $color-royal-one;
  }
}
