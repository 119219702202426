.datepicker {
  background-color: $color-brand-tertiary;
  position: relative;
  padding-right: 45px;

  &:disabled {
    ~ .datepicker-icon-box {
      &,
      &:focus,
      &:hover {
        cursor: auto;
        outline-color: transparent;
      }
    }
  }

  ~ .datepicker-icon-box {
    padding: 0;

    &:focus,
    &:hover {
      outline: 1px solid $color-royal-one;

      .datepicker-icon--calendar {
        &::after {
          @include spa-input-calendar--focus;
        }

        &__disabled {
          &::after {
            @include spa-input-calendar--disabled;
          }
        }
      }
    }

    .datepicker-icon--calendar {
      &::after {
        @include spa-input-calendar--default; 

        background-color: transparent;
        border-radius: 0;
        content: ' ';
        height: 24px;
        width: 24px;
      }

      &__disabled {
        &,
        &:hover {
          &::after {
            @include spa-input-calendar--disabled;
          }
        }
      }
    }
  }
}
