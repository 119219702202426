.spa-input-prefix--currency {
  color: $bank-dark-gray;
  display: block;
  position: relative;
  font-family: $roboto-family;
  font-size: $spacing-16;
  line-height: $spacing-16;
  padding-left: $spacing-10;
  z-index: 31;


  margin-top: 20px;
  margin-bottom: -32px;
  width: 25px;

  & + .spa-input-text {
    padding-left: 25px;
  }  
}

&.spa-input--condensed {
  .spa-input-prefix--currency {
    margin-top: 12px;
    margin-bottom: -26px;
    font-size: 14px;
  }
}