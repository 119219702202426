
& {
  width: 100%;
  position: relative;
  .spa-input-format {
    font-family: $roboto-medium-family;
    font-size: $input-option-label-font-size;
    display: block;
  }



  &.spa-date-input {
    white-space: nowrap;
    > {
      .spa-input-label,
      .toggle-trigger-link,
      .spa-input-hint,
      .spa-input-error-message {
        display: block;
      }
    }
  }



  .spa-input-label {
    @extend .spa-input-format;
    color: $color-black;
  }

  .spa-input-label--disabled {
    @extend .spa-input-format;

    color: $color-gray-s70;
  }



  &.spa-input-box {
    .spa-input-text,
    .spa-input-textarea,
    .spa-input-select {
      padding: $spacing-12;

      &:hover {
        border-color: $color-royal-one !important;
      }

      &:focus {
        color: $color-black;
        border-color: $color-royal-one;
        background-color: $color-brand-tertiary;
      }

      &:disabled {
        border-color: $color-gray-s20 !important;
        background-color: $color-gray-s05 !important;
        color: $color-gray-s70 !important;
        opacity: 1;
        -webkit-opacity: 1;
        -webkit-text-fill-color: $color-gray-s70;
      }

      &.spa-error-form-input--validation,
      &.spa-error-form-input--required {
        border-color: $color-regal-one;
        color: $color-regal-one;

        option {
          color: $color-black;
        }

        &:focus {
          border-color: $color-regal-one;
          color: $color-regal-one;
        }

        &.datepicker {
          ~ .datepicker-icon-box {
            .datepicker-icon--calendar {
              &::after {
                @include spa-input-calendar--error;
              }
            }
          }
        }
      }
    }
  }



  .spa-input-hint {
    font-family: $roboto-family;
    font-size: rem-calc(12px);
    color: $color-gray;
    line-height: $line-height-16;
    margin: $input-hint-margin;
    margin-top: $spacing-4;
  }



  .spa-input-error-message {
    background: none;
    background-color: transparent;
    color: $color-regal-one;
    font-family: $roboto-family;
    font-size: rem-calc(12px);
    line-height: $line-height-16;
    margin: 0;
    min-height: 0;

    &[aria-hidden='false'] {
      margin: $spacing-4 0 $spacing-16;
      min-height: 18px;
      background-image: icon(warning, (color: $color-regal-one));
      background-size: 16px 16px;
      background-position: left -1px;
      background-repeat: no-repeat;
      padding-left: $spacing-20;
    }
  }



  &.spa-input-box {

    &.has-help {
      &.spa-input-box {
        .spa-input-text.datepicker,
        .spa-input-select {
          background-position: calc(100% - 55px) center;
          padding-right: 82px;
        }
      }

      .spa-input-text {
        padding-right: 52px; 
      }
    }
  }
}
