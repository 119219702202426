$brand-night-blue: #000A23;

.spa-input-select {
  background-color: $color-brand-tertiary;
  background-image: icon(chevronDown, (color: $color-gray));
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  margin: $input-select-margin;
  outline: none;
  padding-right: 40px;
  z-index: $input-z-index-step;

  ~ .toggle-trigger-box {
    &::before {
      border-left: 1px solid $color-gray-s20;
      content: '';
      display: inline-block;
      height: 34px;
      margin-top: 4px;
    }
  }

  .toggle-trigger {
    z-index: 10;
  }

  &:disabled {
    background-image: icon(chevronDown, (color: $color-gray-s20));

    option {
      color: $color-gray-s70 !important;
    }

    ~ .toggle-trigger-box {
      background-color: transparent !important;
    }

    ~ .spa-input-hint {
      color: $color-gray-s70;
    }
  }

  &:focus {
    ~ .spa-input-hint {
      border-color: $brand-night-blue;
    }
  }
}
