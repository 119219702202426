


& .input-help {
  background-color: $color-royal-three-s05;
  border: $spacing-1 solid $color-royal-three-dark;
  border-radius: $input-standard-border-radius;
  display: none;
  font-size: $type-size-14;
  line-height: $line-height-16;
  margin: $spacing-8 0 $spacing-16;
  min-height: 46px;
  padding: 14px 54px $spacing-12 $spacing-12;
  position: relative;

  .head {
    font-family: $roboto-bold-family;
    margin-bottom: $spacing-10;
    padding-right: 60px;
  }

  .body {
    line-height: 20px;
    background-color: $color-royal-three-s05;
    padding: 0;
  } 

  &:visible ~ .spa-input-error-message:visible {
    margin-top: $spacing-16;
  }

  .close-trigger {
    background: icon(close, (color: $color-gray)) center center no-repeat;
    height: 44px;
    margin-right: $spacing-2;
    margin-top: $spacing-2;
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 44px;
    padding: 0;

    &:hover,
    &:focus {
      background: icon(close, (color: $color-royal-one)) center center no-repeat;
      border: $spacing-1 solid $color-royal-one;
      border-radius: $spacing-2;
    }
  }
}


&.spa-input-options--inverse .input-help {
  color: $color-black-alt;
}
